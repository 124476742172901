import { Button, Checkbox, FormControl, FormLabel, HStack, Input, Stack, Text } from "@chakra-ui/react"
import React, { useState } from 'react'

import validator from 'validator'

function Step3({ handleFormData, values, prevStep, handleSubmit, loading, }: any): any {
  
  const [error, setError] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
      // handleFormData("checkbox");

      isChecked ? values.checkbox = "yes" : values.checkbox = "no"
    };

  // after form submit validating the form data using validator
  function submitFormData (event: React.FormEvent<HTMLFormElement>){
    event.preventDefault();

    if ( ! validator.isLength(values.company, {min: 2, max: 100})) {
      setError(true);
    } else {
        handleSubmit()
    }
  };


  return (
    <form onSubmit={submitFormData}>
      <HStack  pb="24px">
        <FormControl>
          <FormLabel htmlFor="company"> Company name </FormLabel>
            <Input id="company" placeholder="Company name" type="text" name="company" 
              onChange={handleFormData("company")}
              defaultValue={values.company}
              height="32px" 
              required
            />
        </FormControl>
          
        <FormControl marginInlineStart="6 !important">
          <FormLabel htmlFor="medical">Medical Specialisation</FormLabel>
          <Input id="medicalProfessionalName" placeholder="Medical Specialisation" type="text" name="medicalProfessionalName"
            onChange={handleFormData("medicalProfessionalName")}
            defaultValue={values.medicalProfessionalName} height="32px" required />
        </FormControl>

      </HStack>

      <HStack>
        <FormControl>
          <FormLabel htmlFor="mln">Medical License Number</FormLabel>
          <Input id="medicalLicenceNumber" required placeholder="Medical License Number" type="text" name="medicalLicenceNumber"
            onChange={handleFormData("medicalLicenceNumber")}
            defaultValue={values.medicalLicenceNumber} height="32px" />
            {error ? (
                <Text style={{ color: "red" }}>
                  Medical Licence Number not long enough
                </Text>
              ) : (
                ""
              )}
        </FormControl>
            
        <FormControl marginInlineStart="6 !important">
          <FormLabel htmlFor="date"> Expiration Date </FormLabel>
            <Input id="licenceExpirationDate" placeholder="DD / MM / YYYY" type="date" name="licenceExpirationDate" required
              onChange={handleFormData("licenceExpirationDate")}
              defaultValue={values.licenceExpirationDate}
              height="32px"
            />  
        </FormControl>
         
      </HStack>

      <HStack pt="24px" width="80%" justify="flex-start" alignItems="baseline">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        ></input>
        <Text>I agree to receive promotional text messages and understand that I can opt out at any time</Text>
        <Input
          type="text"
          id="checkbox"
          name="checkbox"
          // onLoad={handleFormData("checkbox")}
          defaultValue={isChecked ? values.checkbox = "yes" : values.checkbox = "no"}
          display="none"
        ></Input>

      </HStack>
      
       <HStack py="24px" justifyContent="flex-end">
           <Button onClick={prevStep} variant="ghost" > Prev </Button>
          <Button type="submit"> {loading ? "Loading" : "Finish" } </Button>
      </HStack>
    </form>
  )
}

export default Step3