import * as React from "react";

import Disclaimer from '../components/Sections/Disclaimer'
import Layout from "../components/Base/Layout";
import RegisterForm from "../components/Register/RegisterForm";
import { SvgBackground } from "../components/Base/SvgLogo";
import UnAuthContent from "../components/Login/UnAuthContent";

export default function Register() {
  
  return (
    <Layout>
      <UnAuthContent>
        <SvgBackground position="absolute" bottom="0" right="0"></SvgBackground>
        <RegisterForm />
        <Disclaimer></Disclaimer>
      </UnAuthContent>
    </Layout>
  );
}