import { Box, Button, Container, HStack, Heading, Link, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { gql, useMutation } from "@apollo/client";

import { Step } from './Step'
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import { navigate } from "gatsby";
import { steps } from './data'

const REGISTER_USER = gql`
  mutation MyMutation(
    $address1: String  
    $address2: String  
    $email: String  
    $firstName: String
    $lastName: String  
    $password: String  
    $city: String 
    $company: String  
    $postcode: String 
    $state: String
    $country: CountriesEnum
    $phone: String
    $websiteUrl: String
    $medlicence: String,
    $expdate: String,

    $checkbox: String,
    $licenceExpirationDate: String, 
    $medicalLicenceNumber: String, 
    $medicalProfessionalName: String
  ) {
    registerCustomer(
      input: {
        billing: {
          address1: $address1, 
          address2: $address2, 
          city: $city, 
          company: $company, 
          country: $country,
          postcode: $postcode, 
          state: $state
          phone: $phone
        }, 
        shipping : {
          firstName: $medlicence,
          lastName: $expdate,
        }
        licenceExpirationDate: $licenceExpirationDate, 
        medicalLicenceNumber: $medicalLicenceNumber, 
        medicalProfessionalName: $medicalProfessionalName,
        checkbox: $checkbox,
        email: $email, 
        firstName: $firstName, 
        lastName: $lastName, 
        password: $password,
        websiteUrl: $websiteUrl
      }
    ) {
    customer {
      databaseId
      firstName
    }
  }
}
`;

export default function Register() {

  // Displaying different form parts based on step
  const PageDisplay = () => {
    if (currentStep === 0) {
      return <Step1 handleFormData={handleInputData} values={formData} nextStep={nextStep}></Step1>
    } else if (currentStep === 1) {
      return <Step2 handleFormData={handleInputData} values={formData} nextStep={nextStep} prevStep={prevStep}></Step2>
    } else {
      return <Step3 handleFormData={handleInputData} values={formData} handleSubmit={handleSubmit} prevStep={prevStep} loading={loading} ></Step3>
    }
  }

  // Saving Form data 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address1: "",
    address2: "",
    websiteUrl: "",
    phone: "",
    city: "",
    state: "",
    postcode: "",
    country: "AR",
    company: "",

    licenceExpirationDate: "",
    medicalLicenceNumber: "",
    medicalProfessionalName: "", 
    checkbox: "no",
  })

  console.log(formData)
  
  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input: any) => (e: any) => {
    // input value from the form
    const value = e.currentTarget.value;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  // Register User

  const [register, { data, loading, error }] = useMutation(REGISTER_USER);
  const wasSignUpSuccessful = Boolean(data?.registerCustomer?.customer?.firstName);

  const toast = useToast()

  function handleSubmit() {

    register({
      variables: formData,
    }).catch(error => {

      if (error == "Error: Internal server error") {
        navigate('/ty-for-registration');
      }

      console.log(error)
    });

  }

  if (wasSignUpSuccessful) {
    navigate('/ty-for-registration');
  }

  const [currentStep, setStep] = useState(0)

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(currentStep + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(currentStep - 1);
  };
  
  return (
    <>
      <Box bg="bg-surface" position="relative">
        <Container minW="680px" py={{ base: '4', md: '8' }} px="0" >
          <Text fontSize="md" color="blue.400" py="12px">Welcome</Text>
          <Heading fontSize="4xl" > Customer Registration </Heading>
          <Text fontSize="md" py="20px" color="gray.500" >Please note that only licensed medical practitioners can purchase products from SphericMed.</Text>
          <Box bg="white" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
            <Stack p="6" direction={{ base: "column", md: "row" }}  >
              {steps.map((step, id) => (
                <Step
                  key={id}
                  cursor="pointer"
                  onClick={() => setStep(id)}
                  title={step.title}
                  description={step.description}
                  isActive={currentStep === id}
                  isCompleted={currentStep > id}
                  isFirstStep={id === 0}
                  isLastStep={steps.length === id + 1}
                  number={step.number}
                />
              ))}
            </Stack>
            <Box px="24px">

              {PageDisplay()}

              {error ? (
                (error.message.includes('An account is already registered with your email address. <a href="#" class="showlogin">Please log in.</a>') ? (
                  <Text color="red" textAlign="center">
                    You&#39;re already signed up! <Link href="/log-in" color="gray.800" textDecoration="underline">Log in</Link>
                  </Text>
                ) : (
                  <Text color="red">{error.message}</Text>
                ))
              ) : null}

            </Box>
          </Box>
          <Text fontSize="sm" color="gray.500" py="32px" >Your account will be verified within one business day.  We’ll notify you and answer any questions you may have, and make sure you have a positive, rewarding experience.</Text>
        </Container>
      </Box>
    </>
  )
}

