import React from "react"
import { Circle, Icon, SquareProps, Text } from '@chakra-ui/react'

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean
  isActive: boolean
  text: string
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive, text } = props
  return (
    <Circle
      size="10"
      borderWidth={isCompleted ? '0' : '2px'}
      bg={isActive ? 'blue.500' : 'gray.50'}
      borderColor= {isActive ? 'blue.500' : 'gray.50'}
      {...props}
      color={isActive ? "white": "gray.900"}
      >
      <Text> {text}  </Text>
    </Circle>
  )
}