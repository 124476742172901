import { BoxProps, Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'
import { StepCircle } from './StepCircle'

interface StepProps extends BoxProps {
  title: string
  description: string
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
  isFirstStep: boolean
  number: string
}

export const Step = (props: StepProps) => {
  const { number, isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } =
    props
  const isMobile = useBreakpointValue({ base: true, md: false })

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  })

  const array = ["1", "2", "3"]

  return (
    <Stack spacing="4" direction={{ base: 'row', md: 'column' }} flex="1" {...stackProps}>
      <Stack spacing="0" align="center" justifyContent="flex-start" direction={{ base: 'column', md: 'row' }}>
        <Divider
              orientation={orientation}
          borderWidth="1px"
              borderColor="gray.200"
              display={
                isFirstStep ? "none" : 'block'
              }
              maxW="70px"
              marginRight="8px"
            />
        <StepCircle isActive={isActive} isCompleted={isCompleted} text={number} />
       
            <Stack spacing="0.5" pl="8px" pb={isMobile && !isLastStep ? '8' : '0'} align="start" >
                <Text color="gray.900" fontWeight="bold">
                {title}
                </Text>
                <Text color="gray.200" fontSize="sm" m="0" minW="100px" >{description}</Text>
            </Stack>
            
          </Stack>
    </Stack>
  )
}