import { Button, FormControl, FormLabel, HStack, Input, Select, Text } from "@chakra-ui/react"
import React, { useState } from 'react'

import { getAllCountries } from '../hooks/getAllCountries';
import validator from 'validator'

function Step2({ handleFormData, values, nextStep, prevStep }: any): any {

  var allCountries = getAllCountries();
  var regionNames = new Intl.DisplayNames (
    ['EN'], {type: "region"}
  );
  
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  function submitFormData (event: React.FormEvent<HTMLFormElement>){
    event.preventDefault();

    if ( ! validator.isLength(values.address1, {min: 2, max: 100})) {
      setError(true);
    } else if (! validator.isLength(values.city, {min: 2, max: 100})) {
      setError(true);
    } else if (!validator.isLength(values.state, { min: 2, max: 100 })) {
      setError(true);
    } else if (!validator.isLength(values.postcode, { min: 2, max: 100 })) {
      setError(true);
    } else if (!validator.isLength(values.country, { min: 2, max: 100 })) {
      setError(true);
    }
    else {
      nextStep()
    }
  };
    return (
       <form onSubmit={submitFormData}>
          <HStack  pb="24px">
            <FormControl>
                <FormLabel htmlFor="address1">Address Line 1</FormLabel>
                  <Input id="address1" type="text" name="address1" placeholder="Adress Line 1" 
                 onChange={handleFormData("address1")}
              defaultValue={values.address1} height="32px" required />
            {error ? <Text color="red">Adress line is too short</Text> : <></>}
            </FormControl>
                
            <FormControl marginInlineStart="6 !important">
                <FormLabel htmlFor="address2">Address Line 2 (optional)</FormLabel>
                  <Input id="address2" type="text" name="address2" placeholder="Adress Line 2"
               onChange={handleFormData("address2")}
                      defaultValue={values.address2} height="32px" />
            </FormControl>
          </HStack>

          <HStack pb="24px">
            <FormControl>
              <FormLabel htmlFor="city">City</FormLabel>
                <Input id="city" type="text" name="city" placeholder="City" 
                onChange={handleFormData("city")}
              defaultValue={values.city} height="32px" required />
            {error ? <Text color="red">City name is too short</Text> : <></>}
              </FormControl>
                    
              <FormControl marginInlineStart="6 !important">
                <FormLabel htmlFor="state">State</FormLabel>
                  <Input id="state" type="text" name="state" placeholder="State"
                 onChange={handleFormData("state")}
              defaultValue={values.state} height="32px" required />
            {error ? <Text color="red">State name is too short</Text> : <></>}
                </FormControl>
          </HStack>

          <HStack pb="24px">
              <FormControl>
                <FormLabel htmlFor="postcode">Zip Code</FormLabel>
                  <Input id="postcode" type="text" name="postcode" placeholder="Zip Code" 
                  onChange={handleFormData("postcode")}
              defaultValue={values.postcode} height="32px" required />
            {error ? <Text color="red">Zip code is too short</Text> : <></>}
              </FormControl>
                    
              <FormControl marginInlineStart="6 !important">
                <FormLabel htmlFor="country">Country</FormLabel>
                <Select
                  required
                  variant="outline"
                  height="32px"
                  id="country"
                  name="country"
                  onChange={handleFormData("country")}>
              
                  {allCountries.map((item: any) => {
                    return <option value={item.countryCode}>{regionNames.of(item.countryCode)}</option>
                  })}
                </Select>
              {error ? <Text color="red">Country name is too short</Text> : <></>}
            </FormControl>
       
        </HStack>
        
        <HStack pb="24px">
          <FormControl>
              <FormLabel htmlFor="firstName">Phone Number</FormLabel>
              <Input id="phone" type="text" name="phone" placeholder="Phone Number"
              onChange={handleFormData("phone")}
                defaultValue={values.phone} height="32px" required />
              {/* {error ? <Text color="red">First name is too short</Text> : <></>} */}
          </FormControl>
        </HStack>
        
        <HStack py="24px" justifyContent="flex-end">
           <Button onClick={prevStep} variant="ghost" > Prev</Button>
          <Button type="submit"> Next </Button>
        </HStack>
      </form>
    )
}

export default Step2