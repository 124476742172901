import { Button, FormControl, FormLabel, HStack, Input, Text } from "@chakra-ui/react"
import React, { useState } from 'react'

import validator from 'validator'

function Step1({ handleFormData, values, nextStep}: any): any {
     const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    function submitFormData (event: React.FormEvent<HTMLFormElement>){
    event.preventDefault();

    // checking if values are too short
    if ( ! validator.isLength(values.firstName, {min: 2, max: 100})) {
      setError(true);
    } else if (! validator.isLength(values.lastName, {min: 2, max: 100})) {
      setError(true);
    }
    else if (!validator.isLength(values.password, { min: 8, max: 100 })) {
      setError(true);
    } else {
      nextStep()
    }
  };

  return (
    <form onSubmit={submitFormData} method="post">
          <HStack pb="24px">
              <FormControl>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input id="firstName" type="text" name="firstName" placeholder="First Name"
                  onChange={handleFormData("firstName")}
                    defaultValue={values.firstName} height="32px" required />
                  {/* {error ? <Text color="red">First name is too short</Text> : <></>} */}
              </FormControl>
          
              <FormControl marginInlineStart="6 !important">
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input id="lastName" type="text" name="lastName" placeholder="Last Name" 
                  defaultValue={values.lastName}
                  onChange={handleFormData("lastName")}
                  height="32px"  required/>
          {/* {error ? <Text color="red">Last name is too short</Text> : <></>} */}
              </FormControl>
          </HStack>
          <HStack pb="24px">
              <FormControl>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input id="email" type="email" name="email" placeholder="Email" 
                  defaultValue={values.email}
                      onChange={handleFormData("email")}
                        height="32px" required
                        />
              </FormControl>
              
              <FormControl marginInlineStart="6 !important">
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input id="password" type="password" name="password" placeholder="Password" 
                  defaultValue={values.password}
            onChange={handleFormData("password")} height="32px" required />
          {error ? <Text color="red">Password is too short</Text> : <></>}
        </FormControl>
      </HStack>
      
      <HStack py="24px" justifyContent="flex-end">
        <Button type="submit"> Next </Button>
      </HStack>
    </form>
  )
}

export default Step1